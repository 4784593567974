<template>
  <footer class="bg-primary">
    <div class="container mx-auto p-3 text-white">
      <div class="grid grid-cols-2">
        <div>Menu</div>
        <div>Informatie</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style></style>
