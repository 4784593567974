export default [
  {
    title: "Home",
    name: "Home",
  },
  {
    title: "Over ons",
    name: "About",
  },
  {
    title: "Inloggen",
    name: "Login",
  },
];
