<template>
  <section>
    <div class="xl:container mx-auto p-3">
      <h3 class="dark:text-white text-3xl font-serif">Inloggen</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
};
</script>

<style></style>
